
export const ValueElement = ({ value }) => {

    let valueArray = [];
    try {
        valueArray = value ? JSON.parse(value ?? "[]") : [];
    } catch (e) {
        console.log(`ValueElement Failed to format value ${value + ""}`)
    }

    return (
        <div>
            {valueArray?.map(v => <div>{v.value ?? ""}</div>)}
        </div>
    )
}