import { Card, Grid, IconButton, Popover, Tooltip } from "@material-ui/core";
import { IconProfile } from "icons";
import { useState } from "react";
import { CarrierInfoStyle } from "./CarrierInfoStyle";

export const VendorContacts = ({ pocInfo, smallIcon }) => {
    const classes = CarrierInfoStyle()

    const [anchorEl, setAnchorEl] = useState(false);

    const showPOCInfo = (poc) => {
        return <Grid item >
            <div className={classes.label}><label>Name: </label>{poc?.name ?? "-"}</div>
            <div className={classes.label}><label>Email: </label>{poc?.email ?? "-"}</div>
            <div className={classes.label}><label>Phone: </label>{poc?.phone ?? "-"}</div>
        </Grid>
    }

    return (
        <Grid item xs={1}>
            <Tooltip title='Show POC Info for Carrier'>      
                <IconButton 
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    style={{ display: 'flex', alignItems: 'center'}}
                    size={smallIcon ? 'small' : 'medium'}
                >
                    <IconProfile />
                </IconButton>
            </Tooltip>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Card className={classes.card}>
                    <Grid container direction='row' >
                        <Grid item className={classes.icon}>
                            <IconProfile />
                        </Grid>
                        <Grid item className={classes.title}>
                            POC Information
                        </Grid>
                    </Grid>
                    <Grid container className={classes.info} direction='column'>
                        <Grid item className={classes.section}>
                            Primary POC:
                        </Grid>
                            {showPOCInfo(pocInfo?.[0])}
                        {pocInfo?.length > 1 &&
                            <><Grid item className={classes.section}>
                                Secondary POC:
                            </Grid>
                                {showPOCInfo(pocInfo?.[1])}
                            </>
                        }
                    </Grid>
                </Card>               
            </Popover>
        </Grid>
    );
};