import { useGetAllVendorsQuery } from "features/vendors/vendorSlice";
import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import DropdownFilter from "components/DropdownFilter";
import CreateNewCarrier from "./CreateNewCarrier";
import { VendorContacts } from "./VendorContacts";
import { CarrierInfoStyle } from "./CarrierInfoStyle";

const ReservationCarrierInfo = ({ reservationDetails, setReservationDetails, onChangeValue, displayError }) => {
    const classes = CarrierInfoStyle();

    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetAllVendorsQuery();
    vendors = vendors || [];

    const [isNew, setIsNew] = useState(false);
    const [pocInfo, setPocInfo] = useState();

    useEffect(() => {
        if(!!reservationDetails?.carrierInfo?.id)
        {
            setPocInfo(vendors?.find(v => v.id == reservationDetails?.carrierInfo?.id)?.pocInfo);
        }
    }, [reservationDetails?.carrierInfo?.id])

    const onVendorTypeChange = (newVendor) => {
        setIsNew(newVendor);
        if(newVendor) {
            onChangeValue("id", 0, 'carrierInfo');
            // onChangeValue("name", "", 'carrierInfo')
        }
        if(!newVendor)
        {
            setReservationDetails({ ...reservationDetails, ['carrierInfo']: undefined })
        }
    }

    return (
        <Grid container className={classes.carrierField} direction='row' alignItems='center'>
            {!isNew && <>
                <Grid item xs={6} >
                    <div className={classes.labelCarrier}>
                        Carrier
                    </div>                
                    <DropdownFilter
                        id="id"
                        onSelect={(val) => onChangeValue("id", val.id, 'carrierInfo')}
                        elementType="dropdown"
                        values={vendors}
                        withTypeAhead
                        shrink={reservationDetails?.carrierInfo?.id}
                        value={reservationDetails?.carrierInfo?.id}
                    />
                </Grid>
                <Grid item xs={5} className={classes.radioContainer}>
                    <div className={classes.input}>
                        <label className={classes.radioLable}>
                            <input className={classes.radioButton} type="radio" checked={!isNew} onChange={() => onVendorTypeChange(!isNew)} />
                            Existing Carrier
                        </label>
                        <label className={classes.radioLable}>
                            <input className={classes.radioButton} type="radio" checked={isNew} onChange={() => onVendorTypeChange(!isNew)} />
                            New Carrier
                        </label>
                    </div>
                </Grid>
            </>}
            
            {(reservationDetails?.carrierInfo?.id && !!pocInfo?.length) &&
                <VendorContacts pocInfo={pocInfo} />
            }

            {isNew && <CreateNewCarrier 
                reservationDetails={reservationDetails}
                onChangeValue={onChangeValue} 
                isNew={isNew} 
                displayError={displayError}
            />}
        </Grid>
    );
}

export default ReservationCarrierInfo;