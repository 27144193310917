import { Grid } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import PhoneInput from "components/PhoneInput";
import { ReservationFormStyles } from "features/Reservation/NewReservation/Components/ReservationForm/ReservationFormStyles";
import { IconLot } from "icons";
import { usStates } from "services/usStates";
import Validator from "services/validator";

const PocFields = ({object, onChange, displayError, isNew, index, label}) => {
    const classes = ReservationFormStyles();

    return (
        <>
            <Grid item xs={4} className={classes.field}>
                <div className={classes.label}>
                    {(label ? "Secondary " : "") + "POC name"}
                </div>
                <CustomInput
                    id="name"
                    elementType='input'
                    value={object?.[index]?.name}
                    onChange={(val) => onChange("name", val, 'pocInfo', index)}
                />
            </Grid>
            <Grid item xs={6} className={classes.field}>
                <Grid container direction="column">
                    <div className={classes.label}>
                        {(label ? "Secondary " : "") + "POC email"}
                    </div>
                    <CustomInput
                        id="email"
                        elementType='input'
                        value={object?.[index]?.email}
                        valid={Validator.validateEmail(object?.[index]?.email)}
                        touched={object?.[index]?.email}
                        onChange={(val) => onChange("email", val, 'pocInfo', index)}
                    />
                    {isNew && displayError('pocEmail', 'error')}
                </Grid>
            </Grid>
            <Grid item xs={4} className={classes.field}>
                <Grid container direction="column">
                    <div className={classes.label}>
                        {(label ? "Secondary " : "") + 'POC phone number'}
                    </div>
                    <PhoneInput
                        value={object?.[index]?.phone}
                        valid={object?.[index]?.phone && Validator.validatePhone(object?.[index]?.phone)}
                        onChange={(val) => onChange("phone", val, 'pocInfo', index)} 
                    />
                    {isNew && displayError('pocPhone', 'error')}
                </Grid>
            </Grid>

            {/* address fields */}
            <Grid item xs={6} className={classes.field}>
                <div className={classes.label}>
                    {(label ? "Secondary " : "") + 'POC Address'}
                </div>
                <CustomInput
                    id="address"
                    elementType="input"
                    value={object?.[index]?.address}
                    icon={<IconLot />}
                    onChange={(val) => onChange("address", val, 'pocInfo', index)}
                    disabled={!isNew}
                    required
                />
            </Grid>                        
            <Grid item xs={4} className={classes.field}>
                <Grid container direction='column'>
                    <div className={classes.label}>
                        {(label ? "Secondary " : "") + 'POC City'}
                    </div>
                    <CustomInput
                        id="city"
                        elementType="input"
                        value={object?.[index]?.city}
                        icon={<IconLot />}
                        onChange={(val) => onChange("city", val, 'pocInfo', index)}
                        disabled={!isNew}
                        required
                    />
                    {isNew && displayError('city', 'error')}
                </Grid>
            </Grid>
            <Grid item xs={4} className={classes.field}>
                <Grid container direction='column'>
                    <div className={classes.label}>
                        {(label ? "Secondary " : "") + 'POC State'}
                    </div>
                    <CustomInput
                        id="state"
                        elementType="dropdown"
                        value={object?.[index]?.state}
                        values={[{"name": "", "id": ""}, ...usStates]}
                        icon={<IconLot />}
                        onChange={(val) => onChange("state", val, 'pocInfo', index)}
                        disabled={!isNew}
                    />
                    {isNew && displayError('state', 'error')}
                </Grid>
            </Grid>
                <Grid item xs={4} className={classes.field}>
                <Grid container direction='column'>
                    <div className={classes.label}>
                        {(label ? "Secondary " : "") + 'POC Zipcode'}
                    </div>
                    <CustomInput
                        id="zipcode"
                        elementType="input"
                        value={object?.[index]?.zipCode}
                        icon={<IconLot />}
                        onChange={(val) => onChange("zipCode", val, 'pocInfo', index)}
                        disabled={!isNew}
                        required
                    />
                    {isNew && displayError('zipcode', 'error')}
                </Grid>
            </Grid>
        </>
    )
}

export default PocFields;