import { Checkbox, Grid, IconButton, TextField } from "@material-ui/core";
import { indexOf, orderBy } from "lodash";
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { FormBuilderStyles } from "../../style";
import { questionTypes } from "../../formBuilderConsts";
import { useGetVehicleTypesQuery } from "features/vehicles/vehicleSlice";
import { ListFieldMapping } from "./ListFieldMapping";
import { useEffect } from "react";

const QuestionValues = ({
    form,
    question,
    onFieldChange,
    presetValues
}) => {

    const classes = FormBuilderStyles();

    const values = orderBy((presetValues && !question?.values?.length) ? presetValues?.map((v, i) => ({ ...v, value: v.id, order: i })) : [...question?.values ?? [], ...(presetValues ? [] : [{ value: '', isNew: true, order: question?.values?.length }])] ?? [{}], 'order');

    const getValues = () => {
        return values.filter(v => v.value);
    }

    const onValueChange = (newVal, index, otherProps) => {
        let valuesToSave = getValues();
        const { isFailure, valToMap, desc } = (otherProps ?? {});
        if (index == values?.length - 1) {
            valuesToSave = values;//Saving the new option
            const { isNew, ...others } = valuesToSave[index];
            valuesToSave[index] = others;
        }
        var prevValue = valuesToSave[index];
        valuesToSave[index] = { ...prevValue, value: newVal, isFailure, valToMap, desc };
        onFieldChange('values', valuesToSave?.map((v, index) => ({ ...v, order: index })));
    }

    const onIsFailureChange = (index, option, isFailure) => {
        onValueChange(option?.value, index, { ...option, isFailure });
    }

    const onDescChange = (index, option, desc) => {
        onValueChange(option?.value, index, { ...option, desc });
    }

    const onDeleteValue = (rowIndex) => {
        const valuesToSave = getValues();
        valuesToSave.splice(rowIndex, 1);
        onFieldChange('values', valuesToSave?.map((v, index) => ({ ...v, order: index })));
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const valuesToSave = getValues();
        onFieldChange('values', arrayMove(valuesToSave, oldIndex, newIndex).map((q, index) => ({ ...q, order: index })))
    };

    const mappedToListField = question.questionType === questionTypes.Dropdown &&
        question.mappedToFieldType && question.mappedToField


    return (
        <Grid direction="column" container className={classes.optionsContainer} >
            <div className={classes.optionsContainerTitle}>Options</div>
            <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                {values.map((v, index) => (
                    <SortableItem presetValues={presetValues}
                        index={index} option={v}
                        rowIndex={index}
                        onIsFailureChange={onIsFailureChange}
                        onDescChange={onDescChange}
                        onValueChange={onValueChange}
                        onDeleteValue={onDeleteValue}
                        mappedToListField={mappedToListField}
                    />
                ))}
            </SortableContainer>
        </Grid>
    );
}
export default QuestionValues;



const SortableItem = sortableElement(({ option, rowIndex, onValueChange, onIsFailureChange, onDescChange, onDeleteValue, presetValues, mappedToListField }) => {
    const classes = FormBuilderStyles();
    const DragHandle = sortableHandle(() => <DragIndicatorIcon className={classes.dragIcon} color="secondary" />);
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();

    return (
        <Grid item container alignItems="center" className={classes.multiValueOpt}>
            <Grid item container alignItems="center" spacing={1}>
                {option?.isNew ? <div style={{ width: '1.8em' }}></div> : <DragHandle />}
                {!!option?.id &&
                    <Grid item xs={2}><b>{option?.id}</b></Grid>
                }
                <Grid item xs={8} container alignItems="center">
                    {!!option?.id && <Grid item xs={1}>Alias:</Grid>}
                    <TextField
                        value={option?.value ?? ""}
                        elementType="input"
                        variant='outlined'
                        onChange={e => {
                            onValueChange(e.target.value, rowIndex, option)
                        }}
                        placeholder={option?.isNew ? '+ Type a new option...' : undefined}
                    />
                    {!option?.isNew && !presetValues &&
                        <IconButton onClick={() => onDeleteValue(rowIndex)}>
                            <HighlightOffOutlinedIcon style={{ width: '0.7em' }} />
                        </IconButton>}
                    {!option?.isNew && <label className={classes.isFailure}>
                        <Checkbox
                            color="primary"
                            checked={option?.isFailure ?? false}
                            onChange={(e) => {
                                onIsFailureChange(rowIndex, option, e.target.checked);
                            }}
                        />
                        Failure
                    </label>}
                    {option?.isFailure && <TextField
                        value={option?.desc ?? ""}
                        elementType="input"
                        variant='outlined'
                        onChange={e => {
                            onDescChange(rowIndex, option, e.target.value)
                        }}
                        placeholder={'Failure description'}
                    />}
                </Grid>
            </Grid>
            {/* <Grid item>
                {!option?.isNew && mappedToListField &&
                    //TODO: Make generic
                    <ListFieldMapping
                        value={option?.valToMap}
                        onChangeVal={val => onValueChange(option?.value ?? "", rowIndex, +val)}
                        values={vehicleTypes}
                    />}
            </Grid> */}
        </Grid>
    )
});

const SortableContainer = sortableContainer(({ children }) => {
    return <Grid container direction="column" >
        {children}
    </Grid>;
});