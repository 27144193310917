import { Grid } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import { ReservationFormStyles } from "features/Reservation/NewReservation/Components/ReservationForm/ReservationFormStyles";
import PocFields from "./PocFields";

const VendorFields = ({ object, onChange, displayError, isNew }) => {
    const classes = ReservationFormStyles();

    return (
        <Grid container spacing={2} style={{ marginTop: '1em' }} >
            {/* name and code */}
            <Grid item container direction='row' spacing={2} >
            <Grid item xs={6}>
                <Grid container direction="column">
                    <div className={classes.label}>
                        Name
                    </div>
                    <CustomInput
                        id="name"
                        elementType='input'
                        value={object?.name}
                        onChange={(val) => onChange("name", val, 'carrierInfo')}
                        required
                    />
                    {isNew && displayError('carrierName', 'error')}
                </Grid>
            </Grid>

            <Grid item xs={6}>
                <div className={classes.label}>
                    SCAC 
                </div>
                <CustomInput
                    id="scac"
                    elementType='input'
                    value={object?.scac}
                    onChange={(val) => onChange("scac", val, 'carrierInfo')}
                />
            </Grid>
        </Grid>

        {/* //poc fields name email phone */}
        <PocFields
            object={object?.pocInfo} 
            onChange={onChange}
            displayError={displayError}
            isNew={isNew}
            index={0}
        />

        <PocFields
            object={object?.pocInfo} 
            onChange={onChange}
            displayError={displayError}
            isNew={isNew}
            index={1}
            label
        />
    </Grid>
)}

export default VendorFields;