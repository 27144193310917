import { Button, Dialog, DialogActions, DialogContent, Grid, IconButton } from "@material-ui/core";
import LoadingSpinner from "components/LoadingSpinner";
import NotificationProvider from "components/NotificationProvider";
import { IconReservations } from "icons";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAddReservationMutation, useGetAvailableVehiclesForReservationMutation, useUpdateReservationMutation } from "../../../reservationSlice";
import ReservationAssetTypes from "../ReservationAssetTypes/ReservationAssetTypes";
import ReservationDetails from "../ReservationDetails";
import { ReservationFormStyles } from "./ReservationFormStyles";
import Validator from 'services/validator';
import CloseIcon from '@material-ui/icons/Close';
import usePrompt from "hooks/usePrompt";
import { reservationsStatuses } from "features/Reservation/reservationConsts";
import { Alert } from "@mui/material";

const ReservationForm = ({ reservation, open, setOpen  }) => {
    const classes = ReservationFormStyles();

    const [driverDetails, setDriverDetails] = useState({})
    const [reservationDetails, setReservationDetails] = useState({})
    const [reservationOrder, setReservationOrder] = useState([])
    const [availableVehicleTypesList, setAvailableVehicleTypesList] = useState([])
    const [errors, setErrors] = useState({});
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [valid, setValid] = useState(true);
    const { triggerPrompt } = usePrompt();

    const canSearch = reservationDetails?.pickupLocationId && reservationDetails?.pickupDate && reservationDetails?.pickupTime && reservationDetails?.deliveryLocationId && reservationDetails?.deliveryDate && reservationDetails?.deliveryTime;

    const [addReservation, { isLoading: isAdding }] = useAddReservationMutation();
    const [updateReservation, { isLoading: isSaving }] = useUpdateReservationMutation();

    let [getDriverUsers, { data: availableVehicleTypes, error: availableVehicleTypesError, isLoading: isLoadingAvailableVehicleTypes }] =
        useGetAvailableVehiclesForReservationMutation(
            {
                pickupLocationId: reservationDetails?.pickupLocationId,
                pickupDate: reservationDetails?.pickupDate,
                pickupTime: moment(new Date(reservationDetails?.pickupTime)).format('HH:mm'),
                deliveryLocationId: reservationDetails?.deliveryLocationId,
                deliveryDate: reservationDetails?.deliveryDate,
                deliveryTime: moment(new Date(reservationDetails?.deliveryTime)).format('HH:mm'),
            },
            { skip: !canSearch }
        );

    const resetForm = () => {
        setDriverDetails({});
        setReservationDetails({});
        setReservationOrder([]);
        setAvailableVehicleTypesList([]);
        setErrors({});
        setHasUnsavedChanges(false);
    }

    const search = async (details) => {
        const res = await getDriverUsers(
            {
                pickupLocationId: details?.pickupLocationId,
                pickupDate: details?.pickupDate,
                pickupTime: moment(new Date(details?.pickupTime)).format('HH:mm'),
                deliveryLocationId: details?.deliveryLocationId,
                deliveryDate: details?.deliveryDate,
                deliveryTime: moment(new Date(details?.deliveryTime)).format('HH:mm'),
            }
        )
        setAvailableVehicleTypesList(res.data);
    };

    const save = async () => {
        if (!validate()) {
            return;
        }

        let body = {
            ...driverDetails,
            ...reservationDetails,
            reservationDetails: reservationOrder,
            //pickupTime: moment(new Date(reservationDetails?.pickupTime)).format('HH:mm'),
            //deliveryTime: moment(new Date(reservationDetails?.deliveryTime)).format('HH:mm'),
        }
        let result;
        if (body.id) {
            result = await updateReservation(body);
        } else {
            result = await addReservation(body);
        }

        if (result?.error) {
            NotificationProvider.error('Failed to save the reservation');
        } else {
            NotificationProvider.success('Successfully saved the reservation');
            resetForm();
            setOpen(false);
        }
    }

    useEffect(() => {
        if (reservation) {
            setReservationDetails({ ...reservation });
            setReservationOrder([...(reservation?.reservationDetails ?? [])]);
        }
    }, [reservation])

    const missingDocuments=()=>{
        if((reservationDetails?.coiDoc==null && reservationDetails?.coiDocUrl==null)
            || (reservationDetails?.agreementDoc==null && reservationDetails?.agreementDocUrl==null) 
            || (reservationDetails?.achDoc==null && reservationDetails?.achDocUrl==null)  ){
            return true
        }
        return false
    }
    const allVehicleOnLot=()=>{
        return reservationDetails?.reservationVehicles?.every(v=>v.onLot===true);
    }

    const validate = () => {
        const msgs = {};
        let valid = true;

        if (!reservationDetails?.clientAccountId) { valid = false; msgs['clientAccountId'] = "Client is required" };
        if (!reservationDetails?.pickupDate) { valid = false; msgs['pickupDate'] = "Pickup date is required" };
        //if (!reservationDetails?.pickupTime) { valid = false; msgs['pickupTime'] = "Pickup time is required" };
        //if (!reservationDetails?.deliveryDate) { valid = false; msgs['deliveryDate'] = "Dropoff date is required" };
        //if (!reservationDetails?.deliveryTime) { valid = false; msgs['deliveryTime'] = "Dropoff time is required" };
        if (!(!!reservationOrder?.length && reservationOrder?.some(r => r.quantity > 0))) { valid = false; msgs['assetTypes'] = "At least one asset type and quantity is required" };
        if(reservationDetails?.status==reservationsStatuses.DocumentsReceived)
        { 
            if(missingDocuments()){
                valid = false; 
                msgs['docInvalid'] = "All documents are required when changing the status to Documents Received"
            }
            if(reservationDetails?.insuranceExpiryDate==null){
                valid = false; 
                msgs['insuranceExpiryDate'] = "Insurance Expiry Date is required"
            }
        }
        if(reservationDetails?.status==reservationsStatuses.VehiclesPickedUp && !allVehicleOnLot()){
            valid=false
            msgs['vehicleOffLot'] = "All vehicles need to be checked out when changing the status to Vehicles Picked Up"
        }
        const pocInfo = reservationDetails?.carrierInfo?.pocInfo;

        if(reservationDetails?.carrierInfo?.id == 0)
        {
            if (!reservationDetails?.carrierInfo?.name) { valid = false; msgs['carrierName'] = 'Carrier name is required' };
            if (pocInfo?.[0]?.email!=null && !Validator.validateEmail(pocInfo?.[0]?.email)) { valid = false; msgs['pocEmail'] = "Email invalid" };
            if (pocInfo?.[1]?.email!=null && !Validator.validateEmail(pocInfo?.[1]?.email)) { valid = false; msgs['secPocEmail'] = "Email invalid" };
        
            if (pocInfo?.[0]?.phone!=null && !Validator.validatePhone(pocInfo?.[0]?.phone)) { valid = false; msgs['pocPhone'] = "Phone invalid" };
            if (pocInfo?.[1]?.phone!=null && !Validator.validatePhone(pocInfo?.[1]?.phone)) { valid = false; msgs['secPocPhone'] = "Phone invalid" };
            
            pocInfo?.map((poc, index) => {
                if(poc?.[index]?.address)
                {
                    if (!poc?.[index]?.city) { valid = false; msgs[(index == 1)? 'secondaryCity' : 'city'] = 'City is required' };
                    if (!poc?.[index]?.state) { valid = false; msgs[(index == 1)? 'secondaryState' : 'state'] = 'State is required' };
                    if (!poc?.[index]?.zipCode) { valid = false; msgs[(index == 1)? 'secondaryZipcode' : 'zipcode'] = 'Zipcode is required' };
                }
            })
        }

        setErrors(msgs);
        valid ? setValid(true) : setValid(false);
        
        return valid;
    }

    const onClose = () => {
        if(hasUnsavedChanges)
            {
            triggerPrompt({
                title: "",
                content: "You have unsaved changes, are you sure you want to leave?",
                onConfirm: () => {
                    setOpen(false);
                    resetForm();
                }
            })
        } else {
            setOpen(false);
            resetForm()
        }
    }

    const isLoading = isSaving || isAdding;

    return (
        <>
            <Dialog open={open} maxWidth='md' fullWidth> 
                <Grid container direction="row" alignItems="center" justifyContent="space-between" style={{ padding: '1em' }}>                        
                    <Grid item container xs={10} alignItems="center">
                        <Grid item className={classes.titleIcon}>
                            <IconReservations />
                        </Grid>
                        <Grid item className={classes.title}>
                            {reservation ? 'Reservation Information' : 'New Reservation'}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <IconButton disabled={isLoading} onClick={onClose}><CloseIcon /></IconButton>
                    </Grid>
                </Grid>
                <DialogContent>
                    <Grid container direction="row" spacing={2}>
                        <LoadingSpinner loading={isLoading} />
                        {/* <DriverDetials
                            driverDetails={driverDetails}
                            setDriverDetails={setDriverDetails}
                        /> */}
                        <Grid item>
                            <ReservationDetails
                                reservationDetails={reservationDetails}
                                setReservationDetails={setReservationDetails}
                                search={search}
                                isLoading={isLoadingAvailableVehicleTypes}
                                errors={errors}
                                setErrors={setErrors}
                                setHasUnsavedChanges={setHasUnsavedChanges}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.assetTypeSection}>
                            <ReservationAssetTypes
                                reservationOrder={reservationOrder}
                                setReservationOrder={setReservationOrder}
                                errors={errors}
                                setHasUnsavedChanges={setHasUnsavedChanges}
                            />
                        </Grid>
                        {/* <ReservationSearchResults
                            reservationOrder={reservationOrder}
                            setReservationOrder={setReservationOrder}
                            availableVehicleTypesList={availableVehicleTypesList}
                            isLoading={isLoadingAvailableVehicleTypes}
                        /> */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between" direction="row-reverse"  alignItems= "center" className={classes.footerDialog}>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={save}
                                disabled={isLoading}>
                                {isLoading ? 'Saving' : 'Save'}
                            </Button>
                        </Grid>
                        {!valid? <Grid item  xs={10} className={classes.errorFooterDialog} >
                  <Alert  severity={'error'}>
                   {errors['vehicleOffLot'] !=null? errors['vehicleOffLot']: "Please correct all errors"}
                 </Alert>
                </Grid>:<></>}
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default ReservationForm;
