import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import { useGetConfigurationByVehicleQuery } from "features/vehicles/configurationSlice";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const vehicleTabTypes = {
    DETAILS: 'details',
    ACCESS: 'access',
    CHECK_IN: 'checkin',
    CHECK_OUT: 'checkout'
}

export const vehicleTabMapping = {
    'details': vehicleTabTypes.DETAILS,
    'access': vehicleTabTypes.ACCESS,
    'checkin': vehicleTabTypes.CHECK_IN,
    'checkout': vehicleTabTypes.CHECK_OUT
};

export const VehicleTabs = ({ vehicleId, selected = 0, setSelected, setSelectedKey }) => {

    let { tab } = useParams();
    const tabFromRoute = vehicleTabMapping[tab] || vehicleTabTypes.DETAILS;
    const [selectedTab, setSelectedTab] = useState(tabFromRoute);

    let { data: configuration, error: configurationError, isLoading: isLoadingConfiguration } = useGetConfigurationByVehicleQuery(vehicleId);

    const vehicleTabs = [
        {
            label: 'Asset Details',
            key: vehicleTabTypes.DETAILS
        },
        {
            label: 'Access Groups',
            permission: permissionProfiles.ACCESS.MANAGE_ACCESS,
            key: vehicleTabTypes.ACCESS
        }
    ]

    if (configuration?.useCustomCheckin) {
        vehicleTabs.push({
            label: 'Check In',
            key: vehicleTabTypes.CHECK_IN
        });
    }

    if (configuration?.useCustomCheckOut) {
        vehicleTabs.push({
            label: 'Check Out',
            key: vehicleTabTypes.CHECK_OUT
        });
    }

    const getSelectedTab = () => {
        var tabToReturn = tabFromRoute;
        if (tabFromRoute === vehicleTabTypes.CHECK_IN && !configuration?.useCustomCheckin) {
            tabToReturn = vehicleTabTypes.DETAILS;
        }
        if (tabFromRoute === vehicleTabTypes.CHECK_OUT && !configuration?.useCustomCheckOut) {
            tabToReturn = vehicleTabTypes.DETAILS;
        }
        setSelected(tabToReturn)
        return tabToReturn;
    };

    useEffect(() => {
        if (!isLoadingConfiguration) {
            setSelectedTab(getSelectedTab());
        }
    }, [isLoadingConfiguration, configuration, tabFromRoute]);


    const selectTab = (tab) => {
        setSelected(tab);
    }

    return (
        <TabsWrapper tabs={vehicleTabs} selectedTab={selectedTab} setSelectedTab={selectTab} selectByTabKey />
    )
}