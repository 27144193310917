import FormatService from "services/formatService"

export const DateElement = ({ value }) => {
    let valueStr = value + "";
    try {
        valueStr = FormatService.formatDate((value ? JSON.parse(value ?? "") : ""));
    } catch (e) {
        console.log(`DateElement Failed to format value ${value + ""}`)
    }
    return (
        <>{valueStr}</>
    )
}