import FormatService from "services/formatService";
import { arrayToObject } from "utils/object-util";

export const vehicle_detail_extended_type = {
    bill_of_landing: 1,
    registration_present: 2,
    license_plate: 3,
    DOT_inspection_sticker_present: 4,
    check_engine_light: 5,
    other_dash_light: 6,
    AC_Heat_Radio_working: 7,
    battery_issue: 8,
    low_tire_pressure: 9,
    tire_tread_reading: 10,
    check_spare: 11,
    brake_noise: 12,
    tire_damage: 13,
    windshield_damage: 14,
    body_damage: 15,
    damage_other: 16,
    MISC: 17,
    keys_handed_over: 18,
    fobs_handed_over: 19,
    DOT_last_inspection_date: 20,
    tire_tread_reading_front_left: 21,
    tire_tread_reading_front_right: 22,
    tire_tread_reading_back_left: 23,
    tire_tread_reading_back_right: 24,
    check_under_hood: 25,
    vehicle_title: 26,
    RoofDamage: 27,
    physical_bill_of_landing: 28,
    last_state_inspection_date: 29,
    state_inspection_present: 30,
    storage_type: 31,
    PassengerSideMirrorGlass: 32,
    PassengerSideMirrorSecure: 33,
    PassengerSideMirrorMotor: 34,
    PassengerSideLightCovers: 35,
    PassengerSideAttachments: 36,
    VehicleWeight: 37,
    LeaningWhenParked: 38,
    LeaningTowards: 39,
    FrontPassengerTire: 40,
    FrontPassengerTireRim: 41,
    RearPassengerTire: 42,
    RearPassengerTireRim: 43,
    PassengerSideAnythingHangingUnderVehicle: 44,
    DOTDecal: 45,
    DoorMalfunction: 46,
    InteriorAttachments: 47,
    InteriorHazardLight: 48,
    TurnSignalControl: 49,
    SafteyAlarmsNotWorking: 50,
    WindshieldSystemNotWorking: 51,
    WiperBlades: 52,
    FootBrake: 53,
    ParkingBrake: 54,
    DriverSafteyCamera: 55,
    BackupCamera: 56,
    InAssetEquipment: 57,
    SafteyEquipment: 58,
    FireExtinguisher: 59,
    SteeringWheel: 60,
    Carpets: 61,
    WindshieldAccessory: 62,
    BrandedDecal: 63,
    ExternalCleanliness: 64,
    InternalCleanliness: 65,
    FrontSideLightCovers: 66,
    FrontHazardLight: 67,
    Headlights: 68,
    FrontSideAnythingHangingUnderTheVehicle: 69,
    FrontDriverTire: 70,
    FrontDriverTireRim: 71,
    RearDriverTire: 72,
    RearDriverTireRim: 73,
    DriverSideMirrorGlass: 74,
    DriverSideMirrorSecure: 75,
    DriverSideMirrorMotor: 76,
    DriverSideLightCovers: 77,
    ActiveNonClearFluidLeakingOnTheGround: 78,
    DriverSideAnythingHangingUnderTheVehicle: 79,
    RearHazardLight: 80,
    LicensePlateLight: 81,
    BackSideLightCovers: 82,
    TailLights: 83,
    BackSideAnythingHangingUnderTheVehicle: 84,
    PassengerSideVehicleWeightSticker: 85,
    DriverSideVehicleWeightSticker: 86,
    ItemsAttachedToBody: 87,
    Horn: 88,
    Seatbelts: 89,

}

export const vehicleDetailExtendedGroups = {
    BODY: {
        id: 'BODY',
        label: 'Body/Doors',
        section: 2,
        fields: [
            vehicle_detail_extended_type.DOT_inspection_sticker_present,
            vehicle_detail_extended_type.state_inspection_present,
            vehicle_detail_extended_type.windshield_damage,
            vehicle_detail_extended_type.body_damage,
            //vehicle_detail_extended_type.damage_other,
            //vehicle_detail_extended_type.MISC,
            vehicle_detail_extended_type.check_under_hood,
            vehicle_detail_extended_type.PassengerSideAttachments,
            vehicle_detail_extended_type.PassengerSideAnythingHangingUnderVehicle,
            vehicle_detail_extended_type.DOTDecal,
            vehicle_detail_extended_type.DoorMalfunction,
            vehicle_detail_extended_type.BrandedDecal,
            vehicle_detail_extended_type.FrontSideAnythingHangingUnderTheVehicle,
            vehicle_detail_extended_type.ActiveNonClearFluidLeakingOnTheGround,
            vehicle_detail_extended_type.DriverSideAnythingHangingUnderTheVehicle,
            vehicle_detail_extended_type.BackSideAnythingHangingUnderTheVehicle,
            vehicle_detail_extended_type.PassengerSideVehicleWeightSticker,
            vehicle_detail_extended_type.DriverSideVehicleWeightSticker,
            vehicle_detail_extended_type.ItemsAttachedToBody,
            vehicle_detail_extended_type.RoofDamage,
        ]
    },
    BRAKES: {
        id: 'BRAKES',
        label: 'Brakes',
        section: 3,
        fields: [
            vehicle_detail_extended_type.brake_noise,
            vehicle_detail_extended_type.FootBrake,
            vehicle_detail_extended_type.ParkingBrake,
        ]
    },
    CLEANLINESS: {
        id: 'CLEANLINESS',
        label: 'Cleanliness',
        section: 1,
        fields: [
            vehicle_detail_extended_type.ExternalCleanliness,
            vehicle_detail_extended_type.InternalCleanliness,
        ]
    },
    DOCUMENTATION: {
        id: 'DOCUMENTATION',
        section: 1,
        label: 'Documentation',
        fields: [
            vehicle_detail_extended_type.bill_of_landing,
            vehicle_detail_extended_type.physical_bill_of_landing,
            //vehicle_detail_extended_type.registration_present,
            vehicle_detail_extended_type.vehicle_title,
            vehicle_detail_extended_type.storage_type,
            vehicle_detail_extended_type.keys_handed_over,
            vehicle_detail_extended_type.fobs_handed_over,
            vehicle_detail_extended_type.DOT_last_inspection_date,
            vehicle_detail_extended_type.last_state_inspection_date,
            vehicle_detail_extended_type.VehicleWeight,
        ]
    },
    ELECTRICAL: {
        id: 'ELECTRICAL',
        label: 'Electrical',
        section: 4,
        fields: [
            vehicle_detail_extended_type.check_engine_light,
            vehicle_detail_extended_type.other_dash_light,
            vehicle_detail_extended_type.battery_issue,
            vehicle_detail_extended_type.PassengerSideLightCovers,
            vehicle_detail_extended_type.InteriorHazardLight,
            vehicle_detail_extended_type.TurnSignalControl,
            vehicle_detail_extended_type.SafteyAlarmsNotWorking,
            vehicle_detail_extended_type.BackupCamera,
            vehicle_detail_extended_type.FrontSideLightCovers,
            vehicle_detail_extended_type.FrontHazardLight,
            vehicle_detail_extended_type.Headlights,
            vehicle_detail_extended_type.DriverSideLightCovers,
            vehicle_detail_extended_type.RearHazardLight,
            vehicle_detail_extended_type.LicensePlateLight,
            vehicle_detail_extended_type.BackSideLightCovers,
            vehicle_detail_extended_type.TailLights,
        ]
    },
    HVAC: {
        id: 'HVAC',
        label: 'HVAC Systems',
        section: 4,
        fields: [
            vehicle_detail_extended_type.AC_Heat_Radio_working
        ]
    },
    INSIDE: {
        id: 'INSIDE',
        label: 'Inside',
        section: 1,
        fields: [
            vehicle_detail_extended_type.InteriorAttachments,
            vehicle_detail_extended_type.DriverSafteyCamera,
            vehicle_detail_extended_type.SteeringWheel,
            vehicle_detail_extended_type.Carpets,
            vehicle_detail_extended_type.Horn,
            vehicle_detail_extended_type.Seatbelts
        ]
    },
    // License: {
    //     id: 8,
    //     label: 'License Plate',
    //     fields: [
    //         vehicle_detail_extended_type.license_plate,
    //     ]
    // },
    SAFETY: {
        id: 'SAFETY',
        label: 'Safety Accessories',
        section: 4,
        fields: [
            vehicle_detail_extended_type.InAssetEquipment,
            vehicle_detail_extended_type.SafteyEquipment,
            vehicle_detail_extended_type.FireExtinguisher,
        ]
    },
    MIRRORS: {
        id: 'MIRRORS',
        label: 'Side Mirrors',
        section: 4,
        fields: [
            vehicle_detail_extended_type.PassengerSideMirrorGlass,
            vehicle_detail_extended_type.PassengerSideMirrorSecure,
            vehicle_detail_extended_type.PassengerSideMirrorMotor,
            vehicle_detail_extended_type.DriverSideMirrorGlass,
            vehicle_detail_extended_type.DriverSideMirrorSecure,
            vehicle_detail_extended_type.DriverSideMirrorMotor,
        ]
    },
    TIRES: {
        id: 'TIRES',
        label: 'Tires',
        section: 3,
        fields: [
            vehicle_detail_extended_type.low_tire_pressure,
            //vehicle_detail_extended_type.tire_tread_reading,
            vehicle_detail_extended_type.check_spare,
            //vehicle_detail_extended_type.tire_damage,
            vehicle_detail_extended_type.tire_tread_reading_back_left,
            vehicle_detail_extended_type.tire_tread_reading_back_right,
            vehicle_detail_extended_type.tire_tread_reading_front_left,
            vehicle_detail_extended_type.tire_tread_reading_front_right,
            vehicle_detail_extended_type.LeaningWhenParked,
            vehicle_detail_extended_type.LeaningTowards,
            vehicle_detail_extended_type.FrontPassengerTire,
            vehicle_detail_extended_type.FrontPassengerTireRim,
            vehicle_detail_extended_type.RearPassengerTire,
            vehicle_detail_extended_type.RearPassengerTireRim,
            vehicle_detail_extended_type.FrontDriverTire,
            vehicle_detail_extended_type.FrontDriverTireRim,
            vehicle_detail_extended_type.RearDriverTire,
            vehicle_detail_extended_type.RearDriverTireRim,
        ]
    },
    WINDSHIELD: {
        id: 'WINDSHIELD',
        label: 'Windshield',
        section: 2,
        fields: [
            vehicle_detail_extended_type.WindshieldSystemNotWorking,
            vehicle_detail_extended_type.WiperBlades,
            vehicle_detail_extended_type.WindshieldAccessory,
        ]
    }
}


export const vdFieldGroup = arrayToObject(Object.values(vehicleDetailExtendedGroups)?.flatMap(group => group.fields?.map(field => ({ id: field, group: group?.id }))))

export const vehicleDetailExtendedTypeLabels = {
    [vehicle_detail_extended_type.bill_of_landing]: 'BOL',
    [vehicle_detail_extended_type.registration_present]: 'Physical Registration',
    [vehicle_detail_extended_type.license_plate]: 'License Plate',
    [vehicle_detail_extended_type.DOT_inspection_sticker_present]: 'Dot Inspection Sticker Present',
    [vehicle_detail_extended_type.check_engine_light]: 'Engine Light',
    [vehicle_detail_extended_type.other_dash_light]: 'Other Dash Light',
    [vehicle_detail_extended_type.AC_Heat_Radio_working]: 'AC Heat Radio Working',
    [vehicle_detail_extended_type.battery_issue]: 'Battery Issue',
    [vehicle_detail_extended_type.low_tire_pressure]: 'Low Tire Pressure',
    [vehicle_detail_extended_type.tire_tread_reading]: 'Tire Tread Reading',
    [vehicle_detail_extended_type.check_spare]: 'Spare tire available',
    [vehicle_detail_extended_type.brake_noise]: 'Brake Noise',
    [vehicle_detail_extended_type.tire_damage]: 'Tire Damage',
    [vehicle_detail_extended_type.windshield_damage]: 'Windshield Damage',
    [vehicle_detail_extended_type.body_damage]: 'Body Damage',
    [vehicle_detail_extended_type.damage_other]: 'Other Damage',
    [vehicle_detail_extended_type.MISC]: 'MISC',
    [vehicle_detail_extended_type.keys_handed_over]: 'Number Of Keys Handed Over',
    [vehicle_detail_extended_type.fobs_handed_over]: 'Number Of Fobs',
    [vehicle_detail_extended_type.DOT_last_inspection_date]: 'DOT Inspection Date',
    [vehicle_detail_extended_type.tire_tread_reading_front_left]: 'Tire Tread Reading Front Passenger Side',
    [vehicle_detail_extended_type.tire_tread_reading_front_right]: 'Tire Tread Reading Front Driver Side',
    [vehicle_detail_extended_type.tire_tread_reading_back_left]: 'Tire Tread Reading Rear Passenger Side',
    [vehicle_detail_extended_type.tire_tread_reading_back_right]: 'Tire Tread Reading Rear Driver Side',
    [vehicle_detail_extended_type.check_under_hood]: 'Check Under Hood',
    [vehicle_detail_extended_type.vehicle_title]: 'Vehicle Title',
    [vehicle_detail_extended_type.physical_bill_of_landing]: 'Physical Bill of Landing',
    [vehicle_detail_extended_type.last_state_inspection_date]: 'State Inspection Date',
    [vehicle_detail_extended_type.state_inspection_present]: 'State Inspection Present',
    [vehicle_detail_extended_type.storage_type]: 'StorageType',
    [vehicle_detail_extended_type.PassengerSideMirrorGlass]: 'Passenger Side Mirror Glass',
    [vehicle_detail_extended_type.PassengerSideMirrorSecure]: 'Passenger Side Mirror Secure',
    [vehicle_detail_extended_type.PassengerSideMirrorMotor]: 'Passenger Side Mirror Motor',
    [vehicle_detail_extended_type.PassengerSideLightCovers]: 'Passenger Side Light Covers',
    [vehicle_detail_extended_type.PassengerSideAttachments]: 'Passenger Side Attachments',
    [vehicle_detail_extended_type.VehicleWeight]: 'Vehicle Weight',
    [vehicle_detail_extended_type.LeaningWhenParked]: 'Leaning When Parked',
    [vehicle_detail_extended_type.LeaningTowards]: 'Leaning Towards',
    [vehicle_detail_extended_type.FrontPassengerTire]: 'Front Passenger Tire',
    [vehicle_detail_extended_type.FrontPassengerTireRim]: 'Front Passenger Tire Rim',
    [vehicle_detail_extended_type.RearPassengerTire]: 'Rear Passenger Tire',
    [vehicle_detail_extended_type.RearPassengerTireRim]: 'Rear Passenger Tire Rim',
    [vehicle_detail_extended_type.PassengerSideAnythingHangingUnderVehicle]: 'Passenger Side Anything Hanging Under the Vehicle',
    [vehicle_detail_extended_type.DOTDecal]: 'DOT Decal',
    [vehicle_detail_extended_type.DoorMalfunction]: 'Door Malfunction',
    [vehicle_detail_extended_type.InteriorAttachments]: 'Interior Attachments',
    [vehicle_detail_extended_type.InteriorHazardLight]: 'Interior Hazard Light',
    [vehicle_detail_extended_type.TurnSignalControl]: 'Turn Signal Control',
    [vehicle_detail_extended_type.SafteyAlarmsNotWorking]: 'Saftey Alarms not Working',
    [vehicle_detail_extended_type.WindshieldSystemNotWorking]: 'Windshield System Not Working',
    [vehicle_detail_extended_type.WiperBlades]: 'Wiper blades',
    [vehicle_detail_extended_type.FootBrake]: 'Foot Brake',
    [vehicle_detail_extended_type.ParkingBrake]: 'Parking Brake',
    [vehicle_detail_extended_type.DriverSafteyCamera]: 'Driver Saftey Camera',
    [vehicle_detail_extended_type.BackupCamera]: 'Backup Camera',
    [vehicle_detail_extended_type.InAssetEquipment]: 'In Asset Equipment',
    [vehicle_detail_extended_type.SafteyEquipment]: 'Saftey Equipment',
    [vehicle_detail_extended_type.FireExtinguisher]: 'Fire Extinguisher',
    [vehicle_detail_extended_type.SteeringWheel]: 'Steering Wheel',
    [vehicle_detail_extended_type.Carpets]: 'Carpets',
    [vehicle_detail_extended_type.WindshieldAccessory]: 'Winshield Accessory',
    [vehicle_detail_extended_type.BrandedDecal]: 'Branded Decal',
    [vehicle_detail_extended_type.ExternalCleanliness]: 'External Cleanliness',
    [vehicle_detail_extended_type.InternalCleanliness]: 'Internal Cleanliness',
    [vehicle_detail_extended_type.FrontSideLightCovers]: 'Front Side Light Covers',
    [vehicle_detail_extended_type.FrontHazardLight]: 'Front Hazard Light',
    [vehicle_detail_extended_type.Headlights]: 'Headlights',
    [vehicle_detail_extended_type.FrontSideAnythingHangingUnderTheVehicle]: 'Front Side Anything Hanging Under the Vehicle',
    [vehicle_detail_extended_type.FrontDriverTire]: 'Front Driver Tire',
    [vehicle_detail_extended_type.FrontDriverTireRim]: 'Front Driver Tire Rim',
    [vehicle_detail_extended_type.RearDriverTire]: 'Rear Driver Tire',
    [vehicle_detail_extended_type.RearDriverTireRim]: 'Rear Driver Tire Rim',
    [vehicle_detail_extended_type.DriverSideMirrorGlass]: 'Driver Side Mirror Glass',
    [vehicle_detail_extended_type.DriverSideMirrorSecure]: 'Driver Side Mirror Secure',
    [vehicle_detail_extended_type.DriverSideMirrorMotor]: 'Driver Side Mirror Motor',
    [vehicle_detail_extended_type.DriverSideLightCovers]: 'Driver Side Light Covers',
    [vehicle_detail_extended_type.ActiveNonClearFluidLeakingOnTheGround]: 'Active non-clear fluid leaking on the ground',
    [vehicle_detail_extended_type.DriverSideAnythingHangingUnderTheVehicle]: 'Driver Side Anything Hanging Under the Vehicle',
    [vehicle_detail_extended_type.RearHazardLight]: 'Rear Hazard Light',
    [vehicle_detail_extended_type.LicensePlateLight]: 'License Plate Light',
    [vehicle_detail_extended_type.BackSideLightCovers]: 'Back Side Light Covers',
    [vehicle_detail_extended_type.TailLights]: 'Tail Lights',
    [vehicle_detail_extended_type.BackSideAnythingHangingUnderTheVehicle]: 'Back Side Anything Hanging Under the Vehicle',
    [vehicle_detail_extended_type.PassengerSideVehicleWeightSticker]: 'Passenger side vehicle weight sticker',
    [vehicle_detail_extended_type.DriverSideVehicleWeightSticker]: 'Driver side vehicle weight sticker',
    [vehicle_detail_extended_type.ItemsAttachedToBody]: 'Items Attached to the body',
    [vehicle_detail_extended_type.Horn]: 'Horn',
    [vehicle_detail_extended_type.Seatbelts]: 'Seatbelts',
    [vehicle_detail_extended_type.RoofDamage]: 'Roof Damage',
};

export const excludedTypes = [
    //vehicle_detail_extended_type.bill_of_landing,
    vehicle_detail_extended_type.registration_present,
    vehicle_detail_extended_type.license_plate,
    //vehicle_detail_extended_type.DOT_inspection_sticker_present,
    vehicle_detail_extended_type.MISC,
    vehicle_detail_extended_type.tire_damage,
    // vehicle_detail_extended_type.windshield_damage,
    vehicle_detail_extended_type.tire_damage,
    vehicle_detail_extended_type.damage_other,
    vehicle_detail_extended_type.tire_tread_reading,
]

export const tires = [
    vehicle_detail_extended_type.tire_tread_reading,
    vehicle_detail_extended_type.tire_tread_reading_back_left,
    vehicle_detail_extended_type.tire_tread_reading_back_right,
    vehicle_detail_extended_type.tire_tread_reading_front_left,
    vehicle_detail_extended_type.tire_tread_reading_front_right
];

export const inspectionsPresentAndDates = [
    vehicle_detail_extended_type.DOT_inspection_sticker_present,
    vehicle_detail_extended_type.DOT_last_inspection_date,
    vehicle_detail_extended_type.state_inspection_present,
    vehicle_detail_extended_type.last_state_inspection_date,
];

export const storageTypes = [
    { id: 'storage_new', name: "New vehicle" },
    { id: 'storage_used', name: "Used vehicle" },
    { id: 'storage_lmr', name: "Used vehicle storage pending merchants pick-up" },
    { id: 'unknown', name: "Not able to determine" }
]

const DashLightValues = [
    { id: 'Yes', name: 'Yes' },
    { id: 'No', name: 'No' },
    { id: 'Not working', name: 'Not working' }
];

const MirrorGlassValues = [
    { id: 'Damaged', name: 'Damaged' },
    { id: 'Intact', name: 'Intact' }
];
const MirrorGlassSecureValues = [
    { id: 'Secure', name: 'Secure' },
    { id: 'Unsecure', name: 'Unsecure' },
    { id: 'Not Present', name: 'Not present' }
];

const WorkingNotWorkingValues = [
    { id: 'Working', name: 'Working' },
    { id: 'Not Working', name: 'Not working' },
];

const LightCoversValues = [
    { id: 'Good Condition', name: 'Good condition' },
    { id: 'Dirty', name: 'Dirty' },
    { id: 'Cracked', name: 'Cracked' }
];

const LeaningTowardsValues = [
    { id: 'Front', name: 'Front' },
    { id: 'Driver', name: 'Driver side' },
    { id: 'Back', name: 'Back side' },
    { id: 'Passenger', name: 'Passenger' }
];

const TireValues = [
    { id: 'Good', name: 'Good' },
    { id: 'Punctured', name: 'Punctured' },
    { id: 'Cut', name: 'Cut' },
    { id: 'Flat', name: 'Flat' }
];

const TireRimValues = [
    { id: 'Good', name: 'Good' },
    { id: 'Dented', name: 'Dented' },
    { id: 'Missing nuts', name: 'Missing nuts' }
];

const YesNoValues = [
    { id: 'Yes', name: 'Yes' },
    { id: 'No', name: 'No' }
];


const DotDecalValues = [
    { id: 'Clean', name: 'Clean' },
    { id: 'Dirty', name: 'Dirty' },
    { id: 'Damaged', name: 'Damaged' },
    { id: 'Missing', name: 'Missing' }
];

const DoorValues = [
    { id: 'Driver side front', name: 'Driver side front' },
    { id: 'Driver side back', name: 'Driver side back' },
    { id: 'Passenger side front', name: 'Passenger side front' },
    { id: 'Passenger side back', name: 'Passenger side back' },
    { id: 'Cargo', name: 'Cargo' },
    { id: 'Interior', name: 'Interior' },
    { id: 'Back door', name: 'Back door' },
    { id: 'Working', name: 'All working' }
];

const SafetyAlarmsValues = [
    { id: 'Horn', name: 'Horn' },
    { id: 'Back up alarm', name: 'Back up alarm' },
    { id: 'Seat belt warning', name: 'Seat belt warning' },
];

const WindshieldValues = [
    { id: 'Working', name: 'Working' },
    { id: 'Fluid not working', name: 'Fluid not working' },
    { id: 'Wipers not working', name: 'Wipers not working' }
];

const WiperBladedValues = [
    { id: 'Working', name: 'Working' },
    { id: 'Need to be replaced', name: 'Need to be replaced' }
];

const FootBrakeValues = [
    { id: 'Working', name: 'Working' },
    { id: 'Vibrates', name: 'Vibrates' },
    { id: 'Grinding', name: 'Grinding' },
    { id: 'Loose', name: 'Loose' },
    { id: 'Squeeking', name: 'Squeeking' },
    { id: 'Stiff', name: 'Stiff' },
    { id: 'Not working', name: 'Not working' },
];

const ParkingBrakeValues = [
    { id: 'Working', name: 'Working' },
    { id: 'Loose', name: 'Loose' },
    { id: 'Weak or stiff', name: 'Weak or stiff' },
    { id: 'Not working', name: 'Not working' },
];

const DriverSafteyCameraValues = [//Working, not working, missing
    { id: 'Working', name: 'Working' },
    { id: 'Missing', name: 'Missing' },
    { id: 'Not working', name: 'Not working' },
];

const BackupCameraValues = [//Backup Cammera:  working, missing, broken, unsecure, obstructed, or not working
    { id: 'Working', name: 'Working' },
    { id: 'Missing', name: 'Missing' },
    { id: 'Broken', name: 'Broken' },
    { id: 'Unsecure', name: 'Unsecure' },
    { id: 'Obstructed', name: 'Obstructed' },
    { id: 'Not working', name: 'Not working' },
];

const InAssetEquipmentValues = [//In Asset equipment,  Present, damaged, missing, usecure
    { id: 'Present', name: 'Present' },
    { id: 'Damaged', name: 'Damaged' },
    { id: 'Unsecure', name: 'Unsecure' },
    { id: 'Missing', name: 'Missing' }
];

const SafteyEquipmentValues = [//Saftey equipment- Present, damaged, missing, usecure
    { id: 'Present', name: 'Present' },
    { id: 'Damaged', name: 'Damaged' },
    { id: 'Unsecure', name: 'Unsecure' },
    { id: 'Missing', name: 'Missing' }
];


const FireExtinguisherValues = [//Fire Extinguisher- Present, Expired, Missing
    { id: 'Present', name: 'Present' },
    { id: 'Expired', name: 'Expired' },
    { id: 'Missing', name: 'Missing' }
];

const SteeringWheelValues = [//Steering wheel -  Vibrating, stiff, loose, needs alignment, working
    { id: 'Working', name: 'Working' },
    { id: 'Vibrating', name: 'Vibrating' },
    { id: 'Stiff', name: 'Stiff' },
    { id: 'Loose', name: 'Loose' },
    { id: 'Needs alignment', name: 'Needs alignment' }
];


const CarpetValues = [
    { id: 'Clean', name: 'Clean' },
    { id: 'Dirty', name: 'Dirty' },
    { id: 'Large tears', name: 'Large tears' }
];


const WindshieldAccessoryValues = [
    { id: 'Present', name: 'Present' },
    { id: 'Broken', name: 'Broken' },
    { id: 'Missing', name: 'Missing' }
];

const BrandedDecalValues = [
    { id: 'Present', name: 'Present' },
    { id: 'Dirty', name: 'Dirty' },
    { id: 'Has unapproved stickers', name: 'Has unapproved stickers' },
    { id: 'Missing', name: 'Missing' }
];

const ExternalCleanlinessValues = [
    { id: 'Clean', name: 'Clean' },
    { id: 'Dirty', name: 'Dirty' }
];

const InternalCleanlinessValues = [
    { id: 'Clean', name: 'Clean' },
    { id: 'Dirty', name: 'Dirty' }
];

const HeadlightValues = [
    { id: 'Working', name: 'Working' },
    { id: 'Bulb burnt out', name: 'Bulb burnt out' },
    { id: 'Not working', name: 'Not working' }
];

const TailLightValues = [//Tai lights - Working, Brake Bulb burnt out, Tail Light Bulb Burnt Out, Reverse Light Bulb Burnt out, Not Workinig
    { id: 'Working', name: 'Working' },
    { id: 'Brake Bulb burnt out', name: 'Brake Bulb burnt out' },
    { id: 'Tail Light bulb burnt out', name: 'Tail Light bulb burnt out' },
    { id: 'Reverse light bulb burnt out', name: 'Reverse light bulb burnt out' },
    { id: 'Not working', name: 'Not working' }
];

const ACHeatValues = [
    { id: 'AC Not Working', name: 'AC Not Working' },
    { id: 'Heat not working', name: 'Heat not working' },
    { id: 'Windshield Defogger not working', name: 'Windshield Defogger not working' },
    { id: 'Rear Defroster not working', name: 'Rear Defroster not working' },
    { id: 'Rear Defroster not working', name: 'Rear Defroster not working' },
    { id: 'Radio not working', name: 'Radio not working' }
];

const SeatbeltValues = [
    { id: 'Functional', name: 'Functional' },
    { id: 'Torn or not working', name: 'Torn or not working' }
];

const RoofDamageValues = [
    { id: 'Damaged', name: 'Damaged' },
    { id: 'Not Damaged', name: 'Not Damaged' }
];

const KeysFobsValues = [
    { id: '0', name: '0' },
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4+', name: '4+' }
];


export const vehicleDetailExtendedMulti = {
    [vehicle_detail_extended_type.LeaningTowards]: true,
    [vehicle_detail_extended_type.DoorMalfunction]: true,
    [vehicle_detail_extended_type.SafteyAlarmsNotWorking]: true,
    //[vehicle_detail_extended_type.AC_Heat_Radio_working]: true
}

export const vehicleDetailExtendedDate = {
    [vehicle_detail_extended_type.DOT_last_inspection_date]: true,
    [vehicle_detail_extended_type.last_state_inspection_date]: true,
}

export const vehicleDetailExtendedValues = {
    [vehicle_detail_extended_type.keys_handed_over]: KeysFobsValues,
    [vehicle_detail_extended_type.fobs_handed_over]: KeysFobsValues,
    [vehicle_detail_extended_type.bill_of_landing]: YesNoValues,
    [vehicle_detail_extended_type.state_inspection_present]: YesNoValues,
    [vehicle_detail_extended_type.DOT_inspection_sticker_present]: YesNoValues,
    [vehicle_detail_extended_type.check_engine_light]: YesNoValues,
    [vehicle_detail_extended_type.AC_Heat_Radio_working]: YesNoValues,//ACHeatValues
    [vehicle_detail_extended_type.battery_issue]: YesNoValues,
    [vehicle_detail_extended_type.low_tire_pressure]: YesNoValues,
    [vehicle_detail_extended_type.check_spare]: YesNoValues,
    [vehicle_detail_extended_type.brake_noise]: YesNoValues,
    [vehicle_detail_extended_type.windshield_damage]: YesNoValues,
    [vehicle_detail_extended_type.body_damage]: YesNoValues,
    [vehicle_detail_extended_type.vehicle_title]: YesNoValues,
    [vehicle_detail_extended_type.physical_bill_of_landing]: YesNoValues,
    [vehicle_detail_extended_type.storage_type]: storageTypes,
    [vehicle_detail_extended_type.other_dash_light]: DashLightValues,
    [vehicle_detail_extended_type.PassengerSideMirrorGlass]: MirrorGlassValues,
    [vehicle_detail_extended_type.PassengerSideMirrorSecure]: MirrorGlassSecureValues,
    [vehicle_detail_extended_type.PassengerSideMirrorMotor]: WorkingNotWorkingValues,
    [vehicle_detail_extended_type.PassengerSideLightCovers]: LightCoversValues,
    [vehicle_detail_extended_type.LeaningWhenParked]: YesNoValues,
    [vehicle_detail_extended_type.LeaningTowards]: LeaningTowardsValues,
    [vehicle_detail_extended_type.FrontPassengerTire]: TireValues,
    [vehicle_detail_extended_type.FrontPassengerTireRim]: TireRimValues,
    [vehicle_detail_extended_type.RearPassengerTire]: TireValues,
    [vehicle_detail_extended_type.RearPassengerTireRim]: TireRimValues,
    [vehicle_detail_extended_type.PassengerSideAnythingHangingUnderVehicle]: YesNoValues,
    [vehicle_detail_extended_type.DOTDecal]: DotDecalValues,
    [vehicle_detail_extended_type.DoorMalfunction]: DoorValues,
    [vehicle_detail_extended_type.InteriorHazardLight]: WorkingNotWorkingValues,
    [vehicle_detail_extended_type.TurnSignalControl]: WorkingNotWorkingValues,
    [vehicle_detail_extended_type.SafteyAlarmsNotWorking]: SafetyAlarmsValues,
    [vehicle_detail_extended_type.WindshieldSystemNotWorking]: WindshieldValues,
    [vehicle_detail_extended_type.WiperBlades]: WiperBladedValues,
    [vehicle_detail_extended_type.FootBrake]: FootBrakeValues,
    [vehicle_detail_extended_type.ParkingBrake]: ParkingBrakeValues,
    [vehicle_detail_extended_type.DriverSafteyCamera]: DriverSafteyCameraValues,
    [vehicle_detail_extended_type.BackupCamera]: BackupCameraValues,
    [vehicle_detail_extended_type.InAssetEquipment]: InAssetEquipmentValues,
    [vehicle_detail_extended_type.SafteyEquipment]: SafteyEquipmentValues,
    [vehicle_detail_extended_type.FireExtinguisher]: FireExtinguisherValues,
    [vehicle_detail_extended_type.SteeringWheel]: SteeringWheelValues,
    [vehicle_detail_extended_type.WindshieldAccessory]: WindshieldAccessoryValues,
    [vehicle_detail_extended_type.Carpets]: CarpetValues,
    [vehicle_detail_extended_type.BrandedDecal]: BrandedDecalValues,
    [vehicle_detail_extended_type.ExternalCleanliness]: ExternalCleanlinessValues,
    [vehicle_detail_extended_type.InternalCleanliness]: InternalCleanlinessValues,
    [vehicle_detail_extended_type.FrontSideLightCovers]: LightCoversValues,
    [vehicle_detail_extended_type.FrontHazardLight]: WorkingNotWorkingValues,
    [vehicle_detail_extended_type.Headlights]: HeadlightValues,
    [vehicle_detail_extended_type.FrontDriverTire]: TireValues,
    [vehicle_detail_extended_type.FrontDriverTireRim]: TireRimValues,
    [vehicle_detail_extended_type.RearDriverTire]: TireValues,
    [vehicle_detail_extended_type.RearDriverTireRim]: TireRimValues,
    [vehicle_detail_extended_type.DriverSideMirrorGlass]: MirrorGlassValues,
    [vehicle_detail_extended_type.DriverSideMirrorSecure]: MirrorGlassSecureValues,
    [vehicle_detail_extended_type.DriverSideMirrorMotor]: WorkingNotWorkingValues,
    [vehicle_detail_extended_type.DriverSideLightCovers]: LightCoversValues,
    [vehicle_detail_extended_type.ActiveNonClearFluidLeakingOnTheGround]: YesNoValues,
    [vehicle_detail_extended_type.RearHazardLight]: WorkingNotWorkingValues,
    [vehicle_detail_extended_type.LicensePlateLight]: WorkingNotWorkingValues,
    [vehicle_detail_extended_type.BackSideLightCovers]: LightCoversValues,
    [vehicle_detail_extended_type.TailLights]: TailLightValues,
    [vehicle_detail_extended_type.PassengerSideVehicleWeightSticker]: YesNoValues,
    [vehicle_detail_extended_type.DriverSideVehicleWeightSticker]: YesNoValues,
    [vehicle_detail_extended_type.DriverSideAnythingHangingUnderTheVehicle]: YesNoValues,
    [vehicle_detail_extended_type.BackSideAnythingHangingUnderTheVehicle]: YesNoValues,
    [vehicle_detail_extended_type.FrontSideAnythingHangingUnderTheVehicle]: YesNoValues,
    [vehicle_detail_extended_type.Horn]: WorkingNotWorkingValues,
    [vehicle_detail_extended_type.Seatbelts]: SeatbeltValues,
    [vehicle_detail_extended_type.RoofDamage]: RoofDamageValues,
};



export const vehicleExtendedFormatted = (field) => {
    if (vehicleDetailExtendedMulti?.[field?.vehicleDetailExtendedTypeId]) {//field.value && field.value?.contains("[") && field.value?.contains("]")
        try {
            return JSON.parse(field.value ?? "[]")?.map(v => vehicleDetailExtendedValues[field?.vehicleDetailExtendedTypeId]?.find(opt => opt.id == v)?.name)?.join(", ")
        } catch (e) {
            return field.value;
        }

    }
    switch (field?.vehicleDetailExtendedTypeId) {
        case vehicle_detail_extended_type.DOT_last_inspection_date:
        case vehicle_detail_extended_type.last_state_inspection_date:
            return `${FormatService.formatToMonthAndYear(field?.value)}`
        case vehicle_detail_extended_type.storage_type:
            return storageTypes?.find(s => s.id === field?.value)?.name ?? field?.value
        default: {
            return vehicleDetailExtendedValues[field?.vehicleDetailExtendedTypeId] ?
                vehicleDetailExtendedValues[field?.vehicleDetailExtendedTypeId]?.find(v => v.id == field?.value)?.name ?? field?.value :
                field?.value;
        }

    }
}
