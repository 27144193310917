import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Route, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import qs from "qs";
import { isObjectEmpty } from '../../utils/object-util';
import userManager from "../../app/userManager";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { useSessionstorageState } from "rooks";
import { selectIsLoggingOut } from "../user/userSlice";
import Authorize from 'components/Authorize';
import UnauthorizedRoute from './UnauthorizedRoute/UnauthorizedRoute';
import { setToken } from "../user/seamlessTokenSlice";
import { useTitle } from 'hooks/useTitle';
import LoadingSpinner from 'components/LoadingSpinner';

const useStyles = makeStyles((theme) => ({
    table: {
        marginTop: 20
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: "calc(50% - 20px)",
        zIndex: '100'
    }
}));

const PrivateRoute = ({ component: Component, profile, pageTitle, ...rest }) => {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();
    let user = useSelector(state => state.oidc.user);
    const isLoggingOut = useSelector(selectIsLoggingOut);
    const isLoadingUser = useSelector(state => state.oidc.isLoadingUser);
    const [redirectPath, setRedirectPath, removeRedirectPath] = useSessionstorageState("redirectPath");

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const token = queryParams?.token;
    const seamlessToken = token;
    let allowSeamless = !!seamlessToken;

    if (seamlessToken) {
        const decoded = jwtDecode(seamlessToken);
        const vehicleId = decoded.vehicleId;
        allowSeamless = allowSeamless && decoded.role === "seamless" && !!vehicleId && location.pathname === `/assets/detail/${vehicleId}`;
    }

    const checkToken = async () => {
        const userToken = await userManager.getUser();

        if ((userToken === null || isObjectEmpty(userToken) || userToken.expired) && !isLoggingOut && !allowSeamless) {
            let search = seamlessToken ? "" : location.search;
            setRedirectPath(location.pathname + search);
            await userManager.removeUser();
            await userManager.signinRedirect()
                .then(user => console.log('signIn', user));
        }
    };

    useEffect(() => {
        checkToken();
    });
    
    useEffect(() => {
        dispatch(setToken(token));
    }, [token]);

    useTitle(pageTitle ?? "");

    return (
        <Authorize profile={profile} unauthorizedComponent={<>{!user ? <LoadingSpinner loading={true} /> : <UnauthorizedRoute />}</>}>
            <Route
                {...rest}
                render={props => (user || seamlessToken) ? <Component {...props} /> : <CircularProgress className={classes.spinner} />}
            />
        </Authorize>
    );
};

export default PrivateRoute;
