import { Button, Dialog, DialogContent } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { useState } from "react";
import ReservationForm from "./Components/ReservationForm/ReservationForm";

export const AddReservation = ({  }) => {
    const [open, setOpen] = useState();

    return (
        <>
            <Authorize profile={permissionProfiles.RESERVATIONS.EDIT}>
                <Button
                    variant="contained"
                    color='primary'
                    onClick={() => setOpen(true)}
                >
                    Create Reservation
                </Button>
            </Authorize>
            <ReservationForm open={open} setOpen={setOpen} ></ReservationForm>
        </>)
}