import { Grid } from "@material-ui/core";
import { ReservationFormStyles } from "../ReservationForm/ReservationFormStyles";
import VendorFields from "features/vendors/VendorFields";

const CreateNewCarrier = ({ reservationDetails, onChangeValue, isNew, displayError }) => {
    const classes = ReservationFormStyles();

    return (
        <Grid container spacing={2} style={{ marginTop: '1em' }} >
            <div className={classes.carrierTitle}>
                New Carrier
            </div>

            <VendorFields 
                object={reservationDetails?.carrierInfo} 
                onChange={onChangeValue} 
                displayError={displayError}
                isNew={isNew}
            />
        </Grid>
    );
}

export default CreateNewCarrier;