import { apiTags } from 'app/store/apiTags';
import { baseQuery } from 'app/baseQuery';
import { baseApi } from 'app/baseApi';
import FormatService from 'services/formatService';

const apiPrefix = 'reservation';
export const reservationApi = baseApi.injectEndpoints({
    reducerPath: 'reservationApi',
    tagTypes: [apiTags.RESERVATION],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllReservations: builder.query({
            query: () => `${apiPrefix}`,
            providesTags: [apiTags.RESERVATION],
            transformResponse: (response) => {
                return response?.map(res => ({
                    ...res,
                    flaggedStr: res.latestFlag ? 'Yes' : 'No',  
                    formattedPickupDate: FormatService.formatDateNoConversion(res.pickupDate),
                    formattedDeliveryDate: FormatService.formatDateNoConversion(res.deliveryDate),
                    formattedActualDropOffDate: FormatService.formatDateNoConversion(res.actualDropOffDate),
                    formattedActualPickUpDate: FormatService.formatDateNoConversion(res.actualPickUpDate),
                    formattedInsuranceExpiryDate: FormatService.formatDateNoConversion(res.insuranceExpiryDate),
                    formattedRentalRate: (res.rentalRate != null) ? FormatService.formatCurrency(res.rentalRate) : null,
                    primaryPocName: res?.carrierInfo?.pocInfo?.[0]?.name ?? "",              
                    primaryPocPhone: res?.carrierInfo?.pocInfo?.[0]?.phone ?? "",             
                    primaryPocEmail: res?.carrierInfo?.pocInfo?.[0]?.email ?? ""
              
                }))
            }
        }),
        getreservationById: builder.query({
            query: (reservationId) => `${apiPrefix}/${reservationId}`,
            providesTags: [apiTags.RESERVATION],
        }),
        getAvailableVehiclesForReservation: builder.mutation({
            query: (body) => ({
                url: `${apiPrefix}/availableTypes`,
                method: "POST",
                body: body,
            })
        }),
        getUserDrivers: builder.query({
            query: () => `${apiPrefix}/userDrivers`,
        }),
        addReservation: builder.mutation({
            query: reservation => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: reservation
            }),
            invalidatesTags: [apiTags.RESERVATION, apiTags.VENDOR]
        }),
        addVehicle: builder.mutation({
            query: reservation => ({
                url: `${apiPrefix}/addVehicle`,
                method: "POST",
                body: reservation
            }),
            invalidatesTags: [apiTags.RESERVATION],
        }),
        deleteVehicle: builder.mutation({
            query: reservationVehicle => ({
                url: `${apiPrefix}/deleteVehicle`,
                method: "DELETE",
                body: reservationVehicle
            }),
            invalidatesTags: [apiTags.RESERVATION],
        }),
        deleteDocReservation: builder.mutation({
            query: docReservation => ({
                url: `${apiPrefix}/deleteFile`,
                method: "POST",
                body: docReservation
            }),
            invalidatesTags: [apiTags.RESERVATION],
        }),
        addDocReservation: builder.mutation({
            
            query: attachment => ({
                url: `${apiPrefix}/uploadFile`,
                method: "POST",
                body: attachment
            }),
            invalidatesTags: [apiTags.RESERVATION,apiTags.ATTACHMENTS],
        }),
        updateReservation: builder.mutation({
            query: reservation => ({
                url: `${apiPrefix}`,
                method: "PUT",
                body: reservation
            }),
            invalidatesTags: [apiTags.RESERVATION, apiTags.VEHICLE, apiTags.VENDOR,apiTags.ATTACHMENTS]
        }),
        getVehicleByReservationId: builder.query({
            query: (reservationId) => `${apiPrefix}/GetVehicle/${reservationId}`,
            providesTags: [apiTags.RESERVATION],
        }),
        sendDocumentationRequest: builder.mutation({
            query: reservationId => ({
                url: `${apiPrefix}/sendDocumentationRequest`,
                method: "POST",
                body: reservationId
            }),
            invalidatesTags: [apiTags.RESERVATION]
        }),
       
    }),
});

export const {
    useGetAllReservationsQuery,
    useGetreservationByIdQuery,
    useGetAvailableVehiclesForReservationMutation,
    useGetUserDriversQuery,
    useAddReservationMutation,
    useAddDocReservationMutation,
    useDeleteDocReservationMutation,
    useAddVehicleMutation,
    useDeleteVehicleMutation,
    useUpdateReservationMutation,
    useGetVehicleByReservationIdQuery,
    useSendDocumentationRequestMutation
} = reservationApi;